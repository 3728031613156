import React, { useEffect, useState, useCallback } from 'react' // Add useCallback to the import
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import {
  auth,
  fetchUser,
  getAllListingsCollectionGroupQuery,
  searchFirestoreListingsByCategory,
} from '../../firebase'
import { Box } from '@mui/material'
import DashboardProfile from '../DashboardProfile'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../redux/slices/userSlice'
import ListingsContainer from '../ListingsContainer'
import CategoryDropdown from '../CategoryDropdown'
import { updateListingArray } from '../../redux/slices/listingSlice'

function Dashboard() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  const [userData, setUserData] = useState('')
  const listingRedux = useSelector((state) => state.listings.value)

  // Inside your component
  const fetchUserInfo = useCallback(
    async (user) => {
      const res = await fetchUser(user)
      setUserData(res)
      dispatch(updateUser(res))
      if (res.role === 'SELLER') {
        navigate('/dashboard-seller')
      }
    },
    [navigate, dispatch],
  )

  // Use useCallback to wrap the fetchListings function
  const fetchListings = useCallback(async () => {
    const res = await getAllListingsCollectionGroupQuery()
    dispatch(updateListingArray(res))
  }, [dispatch]) // Dependencies array is empty because it uses `dispatch` which is stable

  const handleSearchChange = (e, value) => {
    if (!value) {
      fetchListings()
    } else {
      const data = searchFirestoreListingsByCategory(value)
      data.then((res) => {
        dispatch(updateListingArray(res))
      })
    }
  }

  useEffect(() => {
    fetchListings()
  }, [fetchListings]) // Now fetchListings is stable and won't cause re-renders

  useEffect(() => {
    if (loading) return
    if (!user) return navigate('/')
    fetchUserInfo(user)
  }, [user, loading, navigate, fetchUserInfo])

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#FFF7E7',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          mb: 3,
        }}
      >
        <DashboardProfile userData={userData} user={user} />
      </Box>
      <CategoryDropdown onChange={handleSearchChange} title="Search" />
      <ListingsContainer listings={listingRedux} />
    </Box>
  )
}

export default Dashboard
