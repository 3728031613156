import React, { useEffect, useState } from 'react'
import { getAllListingsCollectionGroupQuery } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import ListingsContainer from '../ListingsContainer'
import { Box, Button } from '@mui/material'
import CategoryDropdown from '../CategoryDropdown'
import { searchFirestoreListingsByCategory } from '../../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { updateListingArray } from '../../redux/slices/listingSlice'

function BrowsingPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const listingRedux = useSelector((state) => state.listings.value)

  useEffect(() => {
    const successCallback = (position) => {
      console.log('position:', position)
    }

    const errorCallback = (error) => {
      console.log(error)
    }

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  }, [])

  const fetchListings = async () => {
    const res = await getAllListingsCollectionGroupQuery()
    dispatch(updateListingArray(res))
  }

  useEffect(() => {
    fetchListings()
  }, [])

  const handleClickLogin = () => {
    navigate('/')
  }

  const handleSearchChange = (e, value) => {
    if (!value) {
      fetchListings()
    }
    const data = searchFirestoreListingsByCategory(value)
    data.then((res) => {
      dispatch(updateListingArray(res))
    })
  }

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#FFF7E7',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        <h2>Browsing Page</h2>
        <Button variant="contained" color="tertiary" onClick={handleClickLogin}>
          Login
        </Button>
      </Box>
      <CategoryDropdown onChange={handleSearchChange} title="Search" />
      <ListingsContainer browsingPage={true} listings={listingRedux} />
    </Box>
  )
}

export default BrowsingPage
