import { logout } from '../firebase'
import { store } from '../redux/store'

const sellerNavItems = [
  {
    name: 'Home',
    link: '/home',
    func: null,
    requiredStatus: 'ACTIVE',
  },
  {
    name: 'Profile',
    link: `/profile/${store.getState().user.value.uid}`,
    func: null,
    requiredStatus: 'ACTIVE',
  },
  {
    name: 'Create Listing',
    link: '/create',
    func: null,
    requiredStatus: 'ACTIVE',
  },
  {
    name: 'Sold Listings',
    link: `/view/sold`,
    func: null,
    requiredStatus: 'ACTIVE',
  },
  {
    name: 'Expired Listings',
    link: `/view/${store.getState().user.value.uid}/expired`,
    func: null,
    requiredStatus: 'ACTIVE',
  },
  {
    name: 'Logout',
    link: '/',
    func: logout,
  },
]

const navItems = [
  {
    name: 'Home',
    link: '/home',
    func: null,
  },
  {
    name: 'Purchased Listings',
    link: `/view/purchased`,
    func: null,
  },
  {
    name: 'Logout',
    link: '/',
    func: logout,
  },
]

const navItemsPendingDocs = [
  {
    name: 'Logout',
    link: '/',
    func: logout,
  },
]

export { sellerNavItems, navItems, navItemsPendingDocs }
