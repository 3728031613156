import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Typography, Button } from '@mui/material'
import { uploadFiles } from '../../firebase'
import { useSelector } from 'react-redux'

function DocumentUpload({}) {
  const userRedux = useSelector((state) => state.user.value)
  const [files, setFiles] = React.useState([])
  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...files, acceptedFiles])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const displayFiles = (files[0] || []).map((file, i) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: '2px solid black',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
        }}
      >
        <Button>
          <input {...getInputProps()} />
          <Typography>
            Click here to upload W9 and business insurance proof{' '}
          </Typography>
        </Button>
      </Box>
      <aside>
        <Typography variant="h5">Files</Typography>
        <ul>{displayFiles}</ul>
      </aside>
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => uploadFiles(files, userRedux.uid)}
        >
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default DocumentUpload
