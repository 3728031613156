import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const CategoryDropdown = ({ onChange, title, defaultValue, error }) => {
  const categories = [
    { label: 'Lawn Care', value: 'lawn_care', iconPath: '' },
    { label: 'Pool Maintenance', value: 'pool_maintenance' },
    { label: 'Pet Care', value: 'pet_care' },
    { label: 'House Cleaning', value: 'house_cleaning' },
    { label: 'Handyman Services', value: 'handyman_services' },
    { label: 'Home Security Services', value: 'home_security_services' },
    { label: 'Snow Removal', value: 'snow_removal' },
    { label: 'Gardening', value: 'gardening' },
    { label: 'Pest Control', value: 'pest_control' },
    { label: 'Holiday Decor', value: 'holiday_decor' },
    { label: 'Seasonal Check Ups', value: 'seasonal_check_ups' },
    { label: 'Household Upkeep Chores', value: 'household_upkeep_chores' },
    { label: 'Non-medical Home Care Services', value: 'non_medical_home_care' },
    { label: 'Transportation/Errands', value: 'transportation_errands' },
    { label: 'Car Care', value: 'car_care' },
    { label: 'Tasks', value: 'tasks' },
  ]

  const getOptionLabel = (option) => {
    if (Array.isArray(option)) {
      return ''
    }
    return option.label
  }

  const handleOnChange = (event, newValue) => {
    onChange(event, newValue)
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={categories}
      sx={{ width: '100%', mb: 2 }}
      defaultValue={categories.filter(
        (category) => category.value === defaultValue,
      )}
      renderInput={(params) => (
        <TextField size="small" {...params} label={title} error={error} />
      )}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
    />
  )
}

export default CategoryDropdown
