import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

function ZeroBrowsingListDataCard() {
  return (
    <Card sx={{ minWidth: 275, boxShadow: 10, mb: 3, border: 1 }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
        ></Typography>
        <Typography sx={{ textAlign: 'center' }} variant="h5" component="div">
          {'There are no active listings in this category'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ZeroBrowsingListDataCard
