import React, { useEffect, useState } from 'react'
import { getSingleListing } from '../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Box } from '@mui/material'
import ListingDetailCard from '../ListingDetailCard'

function ListingDetailView() {
  const { id, userId } = useParams()
  const navigate = useNavigate()
  const [listing, setListing] = useState({})

  useEffect(() => {
    const fetchListing = async () => {
      const res = await getSingleListing(userId, id)
      setListing(res)
    }
    fetchListing()
  }, [])

  return (
    <Box>
      <Box
        sx={{
          ml: 2,
        }}
      >
        <Button variant="contained" size="small" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Box>
      <Box
        sx={{
          p: 1,
        }}
      >
        <ListingDetailCard listing={listing} />
      </Box>
    </Box>
  )
}

export default ListingDetailView
