import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, registerWithEmailAndPassword } from '../../firebase'
import BuyerSellerAccountToggle from '../BuyerSellerAccountToggle'
import ContactInfo from '../ContactInfo'
import { isValidEmail, isValidPhoneNumber } from '../../helpers/validation'
import Captcha from '../Captcha'

export default function VerticalLinearStepper() {
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  const [activeStep, setActiveStep] = useState(0)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [businessPhone, setBusinessPhone] = useState('')
  const [contactPhone, setContactPhone] = useState('')

  const [role, setRole] = useState('')
  const [accountStatus, setAccountStatus] = useState('')
  const [captchaCompleted, setCaptchaCompleted] = useState(false)

  const handleCaptchaSuccess = (token) => {
    console.log('Captcha verified successfully:', token)
    if (token) setCaptchaCompleted(true)
  }

  const steps = [
    {
      label: 'Select Account Type',
      component: (
        <BuyerSellerAccountToggle
          role={role}
          setRole={setRole}
          setAccountStatus={setAccountStatus}
        />
      ),
    },
    {
      label: 'Account Information',
      component: (
        <ContactInfo
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          businessPhone={businessPhone}
          setBusinessPhone={setBusinessPhone}
          contactPhone={contactPhone}
          setContactPhone={setContactPhone}
          role={role}
        />
      ),
    },
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const register = () => {
    if (!name) {
      alert('Please enter name')
      return
    }
    if (!isValidEmail(email)) {
      alert('Please enter a valid email address')
      return
    }
    if (role === 'SELLER' && !isValidPhoneNumber(businessPhone)) {
      alert('Please enter a valid business phone number')
      return
    }
    if (!isValidPhoneNumber(contactPhone)) {
      alert('Please enter a valid contact phone number')
      return
    }
    registerWithEmailAndPassword(
      name,
      email,
      password,
      address,
      city,
      state,
      zip,
      businessPhone,
      contactPhone,
      accountStatus,
      role,
    )
  }

  useEffect(() => {
    if (loading) return
    if (user) navigate('/home')
  }, [user, loading, navigate])

  return (
    <Box
      sx={{
        height: '85vh',
        maxWidth: 400,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto', // add this to center the component horizontally
        '@media(min-width: 960px)': {
          // use media query to target desktop screens
          maxWidth: '600px', // set a larger max width for larger screens
        },
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.component}
              <Box sx={{ mb: 2, mt: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    disabled={
                      activeStep === 0
                        ? role === ''
                          ? true
                          : false
                        : activeStep === 1
                          ? name === '' ||
                            email === '' ||
                            password === '' ||
                            address === '' ||
                            city === '' ||
                            state === '' ||
                            zip === '' ||
                            contactPhone === ''
                            ? true
                            : false
                          : false
                    }
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
              // Responsive styles applied here
              '@media screen and (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Captcha successCallback={handleCaptchaSuccess} />
          </Box>
          <Button
            variant="contained"
            onClick={register}
            disabled={!captchaCompleted}
            sx={{ mt: 1, mr: 1 }}
          >
            Submit
          </Button>

          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2">
          Already have an account?{' '}
          <Link to="/" style={{ textDecoration: 'none' }}>
            Login
          </Link>{' '}
          now.
        </Typography>
      </Box>
    </Box>
  )
}
