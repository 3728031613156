import { useEffect, useRef, useState } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'

export default function Hcaptcha({ successCallback }) {
  const [token, setToken] = useState(null)
  const captchaRef = useRef(null)

  const onLoad = () => {
    captchaRef.current.execute()
  }

  useEffect(() => {
    if (token) {
      console.log(`hCaptcha Token: ${token}`)
      successCallback(token) // Call success callback with the token
    }
  }, [token, successCallback])

  return (
    <form>
      <HCaptcha
        sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
        onLoad={onLoad}
        onVerify={setToken}
        ref={captchaRef}
      />
    </form>
  )
}
