import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

function ZeroListingDataCard() {
  const navigate = useNavigate()

  const handleCreate = () => {
    navigate('/create')
  }
  return (
    <Card sx={{ minWidth: 275, boxShadow: 10, mb: 3, border: 1 }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
        ></Typography>
        <Typography sx={{ textAlign: 'center' }} variant="h5" component="div">
          {'You have no listings'}
        </Typography>
        <Typography
          sx={{ mb: 1.5, textAlign: 'center' }}
          color="text.secondary"
        >
          {'Click the button below to create a listing'}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" onClick={handleCreate}>
          Create
        </Button>
      </CardActions>
    </Card>
  )
}

export default ZeroListingDataCard
