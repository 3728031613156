import React from 'react'
import {
  Container,
  Typography,
  Box,
  Paper,
  Link,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material'
import logo from '../../assets/logo.png' // Make sure the path is correct

function AboutPage() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 10,
      }}
    >
      <Button
        variant="contained"
        size="small"
        sx={{ mb: 2 }}
        onClick={() => window.history.back()}
      >
        Back
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: 8,
          pb: 6,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            About us
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Alternatives 4 Aging offers a platform that provides access to an
            array of services for assisting individuals with daily tasks. We
            connect seniors to on-demand, as-needed services provided by vetted
            community vendors.
          </Typography>
        </Box>
        <Box
          component="img"
          src={logo}
          alt="Alternatives 4 Aging Logo"
          sx={{
            maxWidth: '100%',
            maxHeight: 250,
            width: isMobile ? '80%' : 'auto',
          }}
        />
      </Box>

      <Box my={4}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 'bold', mt: 4 }}
        >
          Our Goal
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Typography variant="body1" paragraph>
            Our goal is for seniors to remain independent at home by offering
            the ability to connect to supportive services they or their families
            need. A two-sided marketplace design allows individual independent
            contractors, small businesses, or corporations to offer services to
            an engaged customer base.
          </Typography>
        </Paper>
      </Box>

      <Box my={4}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Contact Us
        </Typography>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Typography variant="body1">
            For inquiries, please email us at{' '}
            <Link
              href="mailto:info@alternatives4aging.com"
              sx={{ textDecoration: 'none' }}
            >
              info@alternatives4aging.com
            </Link>
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}

export default AboutPage
