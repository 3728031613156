import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import ListingCard from '../ListingCard'
import ZeroListingDataCard from '../ZeroListingDataCard'
import ZeroBrowsingListDataCard from '../ZeroBrowsingListDataCard'
import { useSelector } from 'react-redux'

function ListingsContainer({ listings, handleDelete, browsingPage }) {
  const userRedux = useSelector((state) => state.user.value)
  const isDesktop = useMediaQuery('(min-width:960px)') // hook to detect screen size

  const cards = listings.map((listing) => (
    <ListingCard
      listing={listing}
      handleDelete={handleDelete ? handleDelete : null}
      userRedux={userRedux}
      key={listing.id}
    />
  ))

  let content

  if (browsingPage) {
    content = listings.length > 0 ? cards : <ZeroBrowsingListDataCard />
  } else if (userRedux.role === 'SELLER') {
    content = listings.length > 0 ? cards : <ZeroListingDataCard />
  } else {
    content = cards
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(350px, 1fr))',
        gap: 3,
        ...(isDesktop && {
          gridTemplateColumns: 'repeat(3, minmax(350px, 1fr))',
        }),
        ...(!isDesktop && {
          display: 'flex',
          flexDirection: 'column',
        }),
      }}
    >
      {content}
    </Box>
  )
}

export default ListingsContainer
