import React, { useEffect, useState, useCallback } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import {
  auth,
  fetchUser,
  getListingsByUser,
  getNonExpiredListingsByUser,
  searchFirestoreListingsByCategory,
} from '../../firebase'
import { Box, CircularProgress } from '@mui/material'
import { updateUser } from '../../redux/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import ListingsContainer from '../ListingsContainer'
import { deleteListing } from '../../firebase'
import DashboardProfile from '../DashboardProfile'
import DocumentUploadView from '../DocumentUploadView'
import CategoryDropdown from '../CategoryDropdown'
import { updateListingArray } from '../../redux/slices/listingSlice'

const SellerDashBoard = () => {
  const dispatch = useDispatch()
  const [user, loading] = useAuthState(auth)
  const [userData, setUserData] = useState('')
  const navigate = useNavigate()
  const userRedux = useSelector((state) => state.user.value)
  const listingRedux = useSelector((state) => state.listings.value)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (loading) return
    if (!user) return navigate('/home')

    const fetchUserInfo = async (user) => {
      setIsLoading(true) // Start loading
      const res = await fetchUser(user)
      setUserData(res)
      dispatch(updateUser(res))
      setTimeout(() => setIsLoading(false), 500) // Ensure minimum loading time
    }

    fetchUserInfo(user)
  }, [user, loading, navigate, dispatch])

  const fetchListings = useCallback(async () => {
    setIsLoading(true) // Start loading
    const res = await getNonExpiredListingsByUser(userRedux?.uid)
    dispatch(updateListingArray(res))
    setTimeout(() => setIsLoading(false), 500) // Ensure minimum loading time
  }, [userRedux, dispatch])

  useEffect(() => {
    fetchListings()
  }, [userRedux, dispatch, fetchListings])

  const handleDelete = async (listing) => {
    setIsLoading(true) // Start loading
    await deleteListing(listing)
    const res = await getListingsByUser(userRedux?.uid)
    dispatch(updateListingArray(res))
    setTimeout(() => setIsLoading(false), 500) // Ensure minimum loading time
  }

  const handleSearchChange = (e, value) => {
    if (!value) {
      fetchListings()
    }
    const data = searchFirestoreListingsByCategory(value)
    data.then((res) => {
      dispatch(updateListingArray(res))
    })
  }

  return (
    <Box
      sx={{
        background: '#FFF7E7',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ p: 1 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                mb: 3,
              }}
            >
              <DashboardProfile userData={userData} user={user} />
            </Box>
            {userRedux.status === 'PENDINGDOCS' ? (
              <DocumentUploadView />
            ) : (
              <>
                <CategoryDropdown
                  onChange={handleSearchChange}
                  title="Search"
                />
                <ListingsContainer
                  listings={listingRedux}
                  handleDelete={handleDelete}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default SellerDashBoard
