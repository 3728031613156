import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  sellerNavItems,
  navItems,
  navItemsPendingDocs,
} from '../../hooks/navItems'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const drawerWidth = 180

function DrawerAppBar(props) {
  // const dispatch = useDispatch();
  const navigate = useNavigate()
  const { window, routes } = props
  const [user] = useAuthState(auth)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const userRedux = useSelector((state) => state.user.value)

  // const fetchUser = async () => {
  //   try {
  //     const q = query(collection(db, "users"), where("uid", "==", user?.uid));
  //     const doc = await getDocs(q);
  //     const data = doc.docs[0].data();
  //     dispatch(updateUser(data));
  //   } catch (err) {
  //     console.log(
  //       "An error occurred while fetching user data from app bar useEffect"
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return navigate("/");
  //   fetchUser();
  // }, [user, loading]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerNavigation = (link) => {
    navigate(link)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={(theme) => ({ textAlign: 'center' })}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.tertiary.main,
        })}
      >
        <Typography variant="h6" sx={() => ({ my: 1, color: 'white' })}>
          A4A
        </Typography>
      </Box>
      <Divider />
      <List>
        {(userRedux.role === 'SELLER' ? sellerNavItems : navItems).map(
          (item) => (
            <>
              <ListItem key={item.name} disablePadding>
                <ListItemButton
                  sx={(theme) => ({
                    textAlign: 'center',
                    '&:hover': {
                      backgroundColor: theme.palette.tertiary.main,
                      boxShadow: '0 4px 7px 3px rgba(0, 0, 0, .2)',
                      transform: 'scale(1.05)',
                    },
                  })}
                  onClick={
                    item.func
                      ? () => item.func()
                      : () => {
                          handleDrawerNavigation(item.link)
                        }
                  }
                >
                  <ListItemText primary={item.name} sx={{ color: 'white' }} />
                </ListItemButton>
              </ListItem>

              <Divider />
            </>
          ),
        )}
      </List>
    </Box>
  )

  const drawerPending = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 1, color: 'white' }}>
        A4A
      </Typography>
      <Divider />
      <List>
        {navItemsPendingDocs.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              onClick={
                item.func
                  ? () => item.func()
                  : () => {
                      handleDrawerNavigation(item.link)
                    }
              }
            >
              <ListItemText primary={item.name} sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" position="sticky" style={{ zIndex: 1100 }}>
          <Toolbar>
            {user && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' }, color: '#fff' }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { sm: 'block' } }}
            >
              Alternatives 4 Aging
            </Typography>
            {user && (
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {(userRedux.role === 'SELLER' ? sellerNavItems : navItems).map(
                  (item) => (
                    <Button
                      key={item.name}
                      sx={(theme) => ({
                        color: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
                        margin: '0 8px',
                        '&:hover': {
                          backgroundColor: theme.palette.tertiary.main,
                          boxShadow: '0 4px 7px 3px rgba(0, 0, 0, .2)',
                          transform: 'scale(1.05)',
                        },
                        textTransform: 'capitalize',
                      })}
                      onClick={
                        item.func
                          ? () => item.func()
                          : () => {
                              handleDrawerNavigation(item.link)
                            }
                      }
                    >
                      {item.name}
                    </Button>
                  ),
                )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
        {user && (
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  backgroundColor: '#5A4AE3', // Set the background color for the whole drawer
                  color: 'white',
                },
              }}
            >
              {userRedux.status === 'PENDINGDOCS' ? drawerPending : drawer}
            </Drawer>
          </Box>
        )}
      </Box>
      <Routes>{routes}</Routes>
    </Box>
  )
}

export default DrawerAppBar
