import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Captcha from '../Captcha'

function BuyerSellerAccountToggle({ setRole, role, setAccountStatus }) {
  const handleSetRole = (role, status) => {
    setRole(role)
    setAccountStatus(status)
  }

  return (
    <Box>
      <Card
        sx={{
          minWidth: 275,
          boxShadow: 3,
          mb: 2,
          borderColor: role === 'BUYER' ? 'green' : 'transparent', // Apply green border if BUYER is selected
          borderWidth: role === 'BUYER' ? 4 : 0, // Adjust border width accordingly
          borderStyle: 'solid',
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Buyer Account
            </Typography>
            {role === 'BUYER' && (
              <CheckCircleIcon style={{ color: 'green', marginLeft: 'auto' }} />
            )}
          </Box>
          <Typography variant="body2">
            Our basic account type used to browse and purchase services.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            sx={{ width: '100%' }}
            size="small"
            variant="contained"
            onClick={() => handleSetRole('BUYER', 'APPROVED')}
          >
            Select
          </Button>
        </CardActions>
      </Card>
      <Card
        sx={{
          minWidth: 275,
          boxShadow: 3,
          borderColor: role === 'SELLER' ? 'green' : 'transparent', // Apply green border if SELLER is selected
          borderWidth: role === 'SELLER' ? 4 : 0, // Adjust border width accordingly
          borderStyle: 'solid',
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Seller Account
            </Typography>
            {role === 'SELLER' && (
              <CheckCircleIcon style={{ color: 'green', marginLeft: 'auto' }} />
            )}
          </Box>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Additional Registration Steps Required
          </Typography>
          <Typography variant="body2">
            W9 form and proof of business insurance required.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            sx={{ width: '100%' }}
            size="small"
            variant="contained"
            onClick={() => handleSetRole('SELLER', 'PENDINGDOCS')}
          >
            Select
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

export default BuyerSellerAccountToggle
