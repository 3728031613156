import React from 'react'
import {
  Box,
  Chip,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
} from '@mui/material'
import BulletList from '../BulletList'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createListingCheckoutSession } from '../../firebase'

function ListingDetailCard({ listing }) {
  const navigate = useNavigate()
  const userRedux = useSelector((state) => state.user.value)
  const {
    title,
    price,
    quantity,
    expirationDate,
    bullet1,
    bullet2,
    bullet3,
    bullet4,
  } = listing

  const handleBuy = async () => {
    if (userRedux.role === undefined) {
      navigate('/')
    } else {
      const sessionUrl = await createListingCheckoutSession({
        listingId: listing.id,
        listingUserId: listing.userId,
        buyerId: userRedux.uid,
      })

      window.location.href = sessionUrl
    }
  }

  return (
    <Card
      sx={{
        boxShadow: 10,
        mb: 3,
        border: 1,
        width: '100%',
        maxWidth: 600,
        margin: 'auto',
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {quantity ? `Limit: ${quantity}` : 'Unlimited'}
          </Typography>
          <Chip
            sx={{ fontSize: '.8rem' }}
            label={`expiring ${expirationDate}`}
          />
        </Box>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {`$${price}`}
        </Typography>
        <BulletList listOfBullets={[bullet1, bullet2, bullet3, bullet4]} />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="small"
          sx={{ width: '100%' }}
          onClick={handleBuy}
        >
          Buy
        </Button>
      </CardActions>
    </Card>
  )
}

export default ListingDetailCard
