import React from 'react'
import DocumentUpload from '../DocumentUpload'
import { Box, Typography } from '@mui/material'

function DocumentUploadView() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">Upload Documents</Typography>
        <Typography sx={{ mb: 5 }}>
          In order for your account to be considered for our platform, you must
          upload the following documents:
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            p: 1,
            mb: 10,
          }}
        >
          <DocumentUpload />
        </Box>
      </Box>
    </Box>
  )
}

export default DocumentUploadView
