import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth, logInWithEmailAndPassword } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Box, TextField, Button } from '@mui/material'
import logo from '../../assets/logo.png'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) navigate('/home')
  }, [user, loading, navigate])
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 75,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#FFF7E7',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          maxWidth: '600px',
          margin: 'auto',
          overflow: 'hidden',
          '@media(min-width: 960px)': {
            maxWidth: '400px',
          },
          '@media(max-width: 599px)': {
            m: 2,
          },
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <img src={logo} style={{ width: '100%', height: '100%' }}></img>
          </Box>
          <TextField
            sx={{
              mb: 2,
            }}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
            size="small"
          />
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            size="small"
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            textAlign: 'center',
            mb: 2,
          }}
        >
          <Link to="/about">About us</Link>
          <Link to="/reset">Forgot Password?</Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            sx={{
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={() => logInWithEmailAndPassword(email, password)}
            color="primary"
          >
            Login
          </Button>
          <Button
            sx={{
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={() => navigate('/browse')}
            color="tertiary"
          >
            Browse Listings
          </Button>
          <Button
            sx={{
              mb: 2,
            }}
            variant="contained"
            size="small"
            onClick={() => navigate('/register')}
            color="secondary"
          >
            Register new account
          </Button>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  )
}
export default Login
