import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins'],
  },
  palette: {
    primary: {
      main: '#5A4AE3',
    },
    secondary: {
      main: '#D8315B',
    },
    tertiary: {
      main: '#FFC24F',
      contrastText: '#ffffff',
    },
    background: {
      default: '#F5F5F5', // A very light gray color for the main background
      paper: '#EFEFEF', // A slightly darker light gray color for paper-like surfaces
    },
  },
})

export default theme
