import React from 'react'
import { Box, TextField } from '@mui/material'

const ContactInfo = ({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  address,
  setAddress,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  role,
  businessPhone,
  setBusinessPhone,
  contactPhone,
  setContactPhone,
}) => {
  return (
    <Box
      sx={{
        m: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="text"
          className="name__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={role === 'BUYER' ? 'Full Name' : 'Business Name'}
        />
        {role === 'SELLER' && (
          <TextField
            sx={{ mb: 2 }}
            size="small"
            type="number"
            value={businessPhone}
            onChange={(e) => setBusinessPhone(e.target.value)}
            placeholder="Business Phone Number"
          />
        )}
        <TextField
          sx={{ mb: 2 }}
          size="small"
          type="number"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          placeholder="Contact Phone Number"
        />
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="text"
          className="email__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="password"
          className="password__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="text"
          className="address__textBox"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
        />
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="text"
          className="city__textBox"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
        />
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="text"
          className="state__textBox"
          value={state}
          onChange={(e) => setState(e.target.value)}
          placeholder="State"
        />
        <TextField
          sx={{
            mb: 2,
          }}
          size="small"
          type="text"
          className="zip__textBox"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          placeholder="Zip Code"
        />
      </Box>
    </Box>
  )
}
export default ContactInfo
