import axios from 'axios'

export const geocode = async (address) => {
  const res = await axios.get('https://api.radar.io/v1/geocode/forward', {
    params: {
      query: address,
    },
    headers: {
      Authorization: 'prj_test_pk_fa5a5c5e5c652a2a651c5e495b2bc3aadbcd19d7',
    },
  })
  return res.data.addresses[0]
}
