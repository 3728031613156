import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import listingsReducer from './slices/listingSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    listings: listingsReducer,
  },
})
