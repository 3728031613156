import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getListingsByUser } from '../../firebase'
import HomeIcon from '@mui/icons-material/Home'
import BusinessIcon from '@mui/icons-material/Business'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Box, Chip, Tooltip } from '@mui/material'

function SellerProfile() {
  const [listings, setListings] = useState([])
  const user = useSelector((state) => state.user.value)

  useEffect(() => {
    if (user && user.uid) {
      getListingsByUser(user.uid).then(setListings)
    }
  }, [user.uid])

  function formatCategory(str) {
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert the string to lowercase
      .replace(/\b[a-z]/g, (char) => char.toUpperCase()) // Convert the first character of each word to uppercase
  }

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px 0',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  }

  const titleStyle = {
    color: '#333',
    marginBottom: '10px',
  }

  const priceStyle = {
    color: '#5cb85c',
    fontWeight: 'bold',
    marginBottom: '10px',
  }

  const descriptionStyle = {
    color: '#666',
    marginBottom: '10px',
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {/* Hero Section */}
      <div style={{ backgroundColor: '#f2f2f2', padding: '20px' }}>
        <img
          src="https://via.placeholder.com/150"
          alt="Business Logo"
          style={{ width: '150px', height: '150px', borderRadius: '50%' }}
        />
        <h1>{user ? user.name : 'Business Name'}</h1>
      </div>

      {/* Horizontal Scrolling Area for Icons */}
      <div style={{ display: 'flex', overflowX: 'auto', padding: '10px' }}>
        <Tooltip title="Aging friendly">
          <HomeIcon style={{ margin: '0 10px' }} />
        </Tooltip>
        <Tooltip title="Dementia friendly">
          <BusinessIcon style={{ margin: '0 10px' }} />
        </Tooltip>
        <Tooltip title="Elderly safe">
          <ShoppingCartIcon style={{ margin: '0 10px' }} />
        </Tooltip>
        {/* Add more icons as needed */}
      </div>

      {/* Listings Section */}
      <Box
        sx={{
          margin: '10px',
        }}
      >
        {listings.map((listing) => (
          <Box key={listing.id} style={cardStyle}>
            <Box
              style={{
                borderBottom: '1px solid #eee',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 style={titleStyle}>{listing.title}</h3>
                <p style={priceStyle}>${listing.price}</p>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Chip
                  label={formatCategory(listing.category)}
                  size="small"
                  sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    fontSize: '.8rem',
                    borderRadius: '10px',
                  })}
                />
                <p style={{ color: '#f0ad4e', marginBottom: '10px' }}>
                  Quantity: {listing.quantity || 'Not defined'}
                </p>
              </Box>
            </Box>
            <Box>
              <p style={descriptionStyle}>{listing.bullet1}</p>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default SellerProfile
