import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { updateListing, getSingleListing } from '../../firebase'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useNavigate, useParams } from 'react-router-dom'
import CategoryDropdown from '../CategoryDropdown'

const EditListing = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.user.value)
  const [title, setTitle] = useState('')
  const [price, setPrice] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [expirationToggle, setExpirationToggle] = useState(true)
  const [quantity, setQuantity] = useState(0)
  const [quantityToggle, setQuantityToggle] = useState(false)
  const { id } = useParams()
  const [bullet1, setBullet1] = useState('')
  const [bullet2, setBullet2] = useState('')
  const [bullet3, setBullet3] = useState('')
  const [bullet4, setBullet4] = useState('')
  const [category, setCategory] = useState('')

  const handleChangeCategory = (e, value) => {
    setCategory(value.value)
  }

  const handleChange = (e, type) => {
    switch (type) {
      case 'title':
        setTitle(e)
        break
      case 'price':
        setPrice(e)
        break
      case 'quantity':
        setQuantity(e)
        break
      case 'bullet1':
        setBullet1(e)
        break
      case 'bullet2':
        setBullet2(e)
        break
      case 'bullet3':
        setBullet3(e)
        break
      case 'bullet4':
        setBullet4(e)
        break
      default:
        return
    }
  }

  const handleChecked = (e, type) => {
    switch (type) {
      case 'expiration':
        if (!quantityToggle && !e) {
          return
        } else {
          setExpirationToggle(e)
        }
        break
      case 'quantity':
        if (!expirationToggle && !e) {
          return
        } else {
          setQuantityToggle(e)
        }
        break
      default:
        return
    }
  }

  const handleDateChange = (newValue) => {
    setExpirationDate(newValue)
  }

  const [errorMessages, setErrorMessages] = useState({
    title: '',
    price: '',
    quantity: '',
    bullet1: '',
    bullet2: '',
    bullet3: '',
    bullet4: '',
    category: '',
  })

  const validateFields = () => {
    const errors = {}
    if (title.trim() === '') errors.title = 'Title is required'
    if (price <= 0) errors.price = 'Price must be greater than 0'
    if (quantityToggle && quantity.trim() === '')
      errors.quantity = 'Quantity is required'
    if (bullet1.trim() === '') errors.bullet1 = 'Bullet 1 is required'
    if (bullet2.trim() === '') errors.bullet2 = 'Bullet 2 is required'
    if (bullet3.trim() === '') errors.bullet3 = 'Bullet 3 is required'
    if (bullet4.trim() === '') errors.bullet4 = 'Bullet 4 is required'
    if (category.trim() === '') errors.category = 'Category is required'
    setErrorMessages(errors)
    return Object.keys(errors).length === 0 // returns true if no errors
  }

  const handleEdit = async () => {
    if (!validateFields()) return
    await updateListing({
      title,
      price,
      expirationDate,
      expirationDateUnix: dayjs(expirationDate).unix(),
      quantity,
      userId: user.uid,
      bullet1,
      bullet2,
      bullet3,
      bullet4,
      id,
      category,
    })
    navigate('/home')
  }

  useEffect(() => {
    const getListing = async () => {
      const listing = await getSingleListing(user.uid, id)
      if (listing.expirationDate) {
        setExpirationToggle(true)
      }
      if (listing.quantity) {
        setQuantityToggle(true)
        setQuantity(listing.quantity)
      }
      setTitle(listing.title)
      setBullet1(listing.bullet1)
      setBullet2(listing.bullet2)
      setBullet3(listing.bullet3)
      setBullet4(listing.bullet4)
      setPrice(listing.price)
      setExpirationDate(listing.expirationDate)
      setCategory(listing.category)
    }
    getListing()
  }, [id])

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <Typography sx={{ mb: 2, fontWeight: 'bold' }}>Edit Listing</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          size="small"
          label="Service Title"
          value={title}
          sx={{ mb: 2, boxShadow: 5, borderRadius: 1 }}
          onChange={(e) => {
            handleChange(e.target.value, 'title')
          }}
          error={errorMessages.title}
        />
        <TextField
          size="small"
          label="Price"
          value={price}
          sx={{ mb: 2, boxShadow: 5, borderRadius: 1 }}
          onChange={(e) => {
            handleChange(e.target.value, 'price')
          }}
          error={errorMessages.price}
        />
        <Box
          sx={{
            border: 1,
            p: 1,
            borderRadius: 2,
            mb: 2,
            borderColor: 'lightGray',
            boxShadow: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mb: 2,
            }}
          >
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Expiration"
              checked={expirationToggle}
              onChange={(e) => handleChecked(e.target.checked, 'expiration')}
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Quantity"
              checked={quantityToggle}
              onChange={(e) => handleChecked(e.target.checked, 'quantity')}
            />
          </Box>
          {expirationToggle && (
            <Box
              sx={{
                mb: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Sale expiration"
                  inputFormat="MM/DD/YYYY"
                  value={expirationDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>
          )}
          {quantityToggle && (
            <TextField
              size="small"
              label="Quantity"
              value={quantity}
              sx={{ mb: 2 }}
              onChange={(e) => {
                handleChange(e.target.value, 'quantity')
              }}
            />
          )}
        </Box>
        <CategoryDropdown
          onChange={handleChangeCategory}
          title="Category"
          defaultValue={category}
          error={errorMessages.category}
        />
        <Box
          sx={{
            border: '1px solid lightGray',
            borderRadius: 1,
            pt: 1,
            pb: 1,
            p: 1,
            mb: 2,
            boxShadow: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ mb: 1 }}>Bullet point description</Typography>
          </Box>
          <TextField
            size="small"
            label="Bullet 1"
            value={bullet1}
            sx={{ mb: 2, borderRadius: 1, width: '100%' }}
            onChange={(e) => {
              handleChange(e.target.value, 'bullet1')
            }}
            error={errorMessages.bullet1}
          />
          <TextField
            size="small"
            label="Bullet 2"
            value={bullet2}
            sx={{ mb: 2, borderRadius: 1, width: '100%' }}
            onChange={(e) => {
              handleChange(e.target.value, 'bullet2')
            }}
            error={errorMessages.bullet2}
          />
          <TextField
            size="small"
            label="Bullet 3"
            value={bullet3}
            sx={{ mb: 2, borderRadius: 1, width: '100%' }}
            onChange={(e) => {
              handleChange(e.target.value, 'bullet3')
            }}
            error={errorMessages.bullet3}
          />
          <TextField
            size="small"
            label="Bullet 4"
            value={bullet4}
            sx={{ mb: 2, borderRadius: 1, width: '100%' }}
            onChange={(e) => {
              handleChange(e.target.value, 'bullet4')
            }}
            error={errorMessages.bullet4}
          />
        </Box>
      </Box>
      <Button variant="contained" onClick={handleEdit}>
        Save
      </Button>
    </Box>
  )
}

export default EditListing
