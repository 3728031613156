import React from 'react'
import { useParams } from 'react-router-dom'
import { getExpiredListings } from '../../firebase'
import ListingsContainer from '../ListingsContainer'
import { Box, Typography } from '@mui/material'
import { deleteListing } from '../../firebase'
import CircularProgress from '@mui/material/CircularProgress'

function ExpiredListings() {
  const [ExpiredListings, setExpiredListings] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const { userId } = useParams()

  React.useEffect(() => {
    setIsLoading(true) // Set loading to true when fetching begins
    getExpiredListings(userId)
      .then((data) => {
        // Use setTimeout to ensure loading state lasts at least 500ms
        setTimeout(() => {
          setExpiredListings(data)
          setIsLoading(false) // Reset loading state when fetching is complete
        }, 500)
      })
      .catch((error) => {
        console.error('Error fetching data: ', error)
        setIsLoading(false) // Ensure loading state is reset on error
      })
  }, [userId])

  const handleDelete = async (listing) => {
    setIsLoading(true) // Set loading to true when deletion begins
    await deleteListing(listing)
    const res = await getExpiredListings(userId)
    setExpiredListings(res)
    setIsLoading(false) // Reset loading state when operation is complete
  }

  return (
    <Box
      sx={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 3 }}>
        Expired Listings
      </Typography>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <ListingsContainer
          listings={ExpiredListings}
          handleDelete={handleDelete}
        />
      )}
    </Box>
  )
}

export default ExpiredListings
