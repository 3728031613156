import React, { useEffect, useState } from 'react'
import { getPurchasedListingsByUser } from '../../firebase'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  CardActions,
  Divider,
  Grid,
  CircularProgress,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import CategoryIcon from '@mui/icons-material/Category'
import { useSelector } from 'react-redux'

function BuyerPurchasedListingsContainer() {
  const userId = useSelector((state) => state.user.value.uid)
  const [purchasedListings, setPurchasedListings] = useState([])
  const [isLoading, setIsLoading] = useState(true) // initialize with true to show loading initially

  useEffect(() => {
    const fetchPurchasedListings = async () => {
      const listings = await getPurchasedListingsByUser(userId)
      setPurchasedListings(listings)
      setIsLoading(false) // set loading to false after fetching data
    }
    fetchPurchasedListings()
  }, [userId])

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // take full viewport height
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        m: 2,
        borderRadius: 1,
      }}
    >
      {purchasedListings.map((listing) => (
        <Card
          key={listing.id}
          sx={{ marginBottom: 2, boxShadow: '5px 5px 5px grey' }}
        >
          <CardHeader
            title={listing.listing.title}
            subheader={`Expiry Date: ${listing.listing.expirationDate}`}
          />
          <Divider />
          <CardContent>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              <CategoryIcon fontSize="small" /> Category:{' '}
              {listing.listing.category}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Price: ${listing.listing.price}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions
            disableSpacing
            sx={{
              padding: '0 16px 8px 16px',
            }}
          >
            <Typography variant="subtitle2" component="span" gutterBottom>
              Seller Info:
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <IconButton edge="end" color="secondary">
                  <a
                    href={`mailto:${listing.sellerContactInfo.email}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <EmailIcon />
                    {listing.sellerContactInfo.email}
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <IconButton edge="end" color="secondary">
                  <a
                    href={`tel:${listing.sellerContactInfo.phone}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <PhoneIcon />
                    {listing.sellerContactInfo.phone}
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      ))}
    </Box>
  )
}

export default BuyerPurchasedListingsContainer
